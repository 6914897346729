import { MutableRefObject, useCallback, useEffect } from 'react'
import ReactPlayer from 'react-player'

import { useGlobalContext } from '@context/GlobalContexts'
import { useIsDesktop } from '@hooks/useIsDesktop'
import runtimeConfig from '@lib/RuntimeConfig'
import { useVideoPlayerContext } from '@pages/url/components/VideoPlayerContext'
import { setHasEverUnmuted, setIsMuted } from '@redux/slices/videoSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'

const MUTE_ACTION_DISPLAY_DURATION = 3000
const CONTROLS_FADE_OUT_DURATION = 3000

export type IVideoPlayer = {
  playerRef: MutableRefObject<ReactPlayer | null>
  disableCustomControls: boolean
}

export const useVideoPlayer = (provider: string): IVideoPlayer => {
  const dispatch = useAppDispatch()
  const { isMuted, hasEverUnmuted } = useAppSelector(state => state.video)
  const { setShowMuteAction, setLastInteractionTime, lastInteractionTime, playerRef } = useVideoPlayerContext()
  const config = runtimeConfig().publicRuntimeConfig
  const isMobile = !useIsDesktop()
  const { isModalOpen } = useGlobalContext()
  const { isLoginModalOpen, isLoginHeroOpen, isLoginPromptOpen } = useAppSelector(state => state.loginModal)
  const isYouTube = provider === 'youtube'
  const disableCustomControls = isYouTube && !isMobile && config.disableCustomYoutubeControls

  useEffect(() => {
    if (hasEverUnmuted) {
      setShowMuteAction(true)
      const timer = setTimeout(() => {
        setShowMuteAction(false)
      }, MUTE_ACTION_DISPLAY_DURATION)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [isMuted, hasEverUnmuted, setShowMuteAction])

  const handleDocumentInteraction = useCallback(() => {
    if (hasEverUnmuted || isMobile || isModalOpen || isLoginModalOpen || isLoginHeroOpen || isLoginPromptOpen) return
    dispatch(setIsMuted(false))
    dispatch(setHasEverUnmuted(true))
  }, [dispatch, hasEverUnmuted, isMobile, isModalOpen, isLoginModalOpen, isLoginHeroOpen, isLoginPromptOpen])

  useEffect(() => {
    if (hasEverUnmuted || isMobile) return
    document.addEventListener('click', handleDocumentInteraction)
    document.addEventListener('touchstart', handleDocumentInteraction)

    return () => {
      document.removeEventListener('click', handleDocumentInteraction)
      document.removeEventListener('touchstart', handleDocumentInteraction)
    }
  }, [handleDocumentInteraction, hasEverUnmuted, isMobile])

  useEffect(() => {
    if (provider === 'soundcloud') {
      dispatch(setIsMuted(false))
    }
  }, [dispatch, provider])

  useEffect(() => {
    let fadeOutTimeout: NodeJS.Timeout | null = null
    if (lastInteractionTime) {
      fadeOutTimeout = setTimeout(() => {
        setLastInteractionTime(null) // Resetting it to hide controls
      }, CONTROLS_FADE_OUT_DURATION)
    }

    return () => {
      if (fadeOutTimeout) clearTimeout(fadeOutTimeout)
    }
  }, [lastInteractionTime, setLastInteractionTime])

  return {
    playerRef,
    disableCustomControls,
  }
}
